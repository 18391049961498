import type { Page_Flexcontent_Flex_ProductsWithFilters, Product, ProductCategory } from '~/graphql/types'
import ProductComponent from './Product'
import { LosseLink, LossePlaatjie, useSearchParams } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import clsx from 'clsx'

export default function ProductsWithFilters({ fields }: { fields: Page_Flexcontent_Flex_ProductsWithFilters }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const productCategories = fields.products?.edges
    ?.reduce((acc, post) => {
      const product = (post.node as Product).woo?.node
      if (!product) return acc
      product.productCategories?.nodes?.forEach((category) => {
        if (category && !acc.find((cat) => cat.databaseId === category.databaseId) && category.parentDatabaseId) {
          acc.push(category)
        }
      })

      return acc
    }, [] as ProductCategory[])
    .sort((a, b) => (a.menuOrder || 0) - (b.menuOrder || 0))

  const productCategoriesWithProducts = productCategories?.map((category) => {
    let products = fields.products?.edges?.reduce((acc, post) => {
      const product = (post.node as Product).woo?.node
      if (!product) return acc
      product.productCategories?.nodes?.forEach((cat) => {
        if (cat && cat.databaseId === category.databaseId) {
          acc.push(post.node as Product)
        }
      })

      return acc
    }, [] as Product[])

    products = products?.sort((a, b) => {
      const aTitle = a.title || ''
      const bTitle = b.title || ''

      return aTitle.localeCompare(bTitle)
    })

    return {
      category,
      products
    }
  })

  return (
    <section className="" data-component="ProductsWithFilters">
      <div className="container mb-10">
        <div className="flex items-center gap-x-8 mb-8">
          <LossePlaatjie src={fields.title?.icon} className="w-[100px] lg:w-[140px] object-contain" maxwidth={300} />
          <div className="content">
            <h1>{fields.title?.text}</h1>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <div className="sm:col-span-2 flex items-center">
            <Content className="content">{fields.description}</Content>
          </div>
          <div className="max-sm:hidden flex items-center justify-center flex-wrap gap-x-2 sm:justify-end">
            {fields.links?.map((link) => (
              <LosseLink
                className="text-rc-sandaconda btn--size btn--base btn--pseudo hover:bg-rc-shedinja hover:text-white inline-flex items-center gap-x-2 min-w-[135px] !font-light"
                to={link?.link?.url}
                key={link?.link?.url}
              >
                <span>{link?.link?.title}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </LosseLink>
            ))}
          </div>
        </div>
      </div>

      {productCategoriesWithProducts && productCategoriesWithProducts.length > 0 && (
        <>
          {productCategories && (
            <div className="bg-rc-shedinja py-4 sm:py-6 mb-10">
              <div className="container flex items-center flex-wrap gap-2 sm:gap-3 lg:gap-4">
                <strong className="md:text-lg">Snel zoeken</strong>

                {productCategories.map((productCategory) => (
                  <button
                    onClick={() => {
                      if (searchParams.get('categoryId') === String(productCategory.databaseId)) {
                        searchParams.delete('categoryId')
                      } else {
                        searchParams.set('categoryId', String(productCategory.databaseId))
                      }

                      setSearchParams(searchParams, {
                        preventScrollReset: true,
                        state: {
                          scroll: false
                        }
                      })
                    }}
                    className={clsx(
                      searchParams.get('categoryId') === String(productCategory.databaseId)
                        ? 'btn'
                        : 'btn--base btn--size btn--pseudo bg-white text-rc-sandaconda text-sm hover:text-white hover:bg-rc-quilladin'
                    )}
                    type="button"
                    key={productCategory.databaseId}
                  >
                    {productCategory.name}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="container">
            {productCategoriesWithProducts
              ?.filter(({ category }) => {
                if (!searchParams.get('categoryId')) return true
                return String(category.databaseId) === searchParams.get('categoryId')
              })
              .map(({ category, products }) => (
                <div className="mb-20" key={category.databaseId}>
                  <h2 className="mb-4 lg:mb-6 text-xl lg:text-2xl font-rc-plus font-bold">{category.name}</h2>

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
                    {products
                      ?.sort((a, b) => {
                        if (a.title === 'Extra optie drinken') return 1
                        if (b.title === 'Extra optie drinken') return -1
                        return 0
                      })
                      ?.map((product) => (
                        <ProductComponent key={product.id} post={product} mobileButtonPlacement="right" />
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}

      {productCategoriesWithProducts?.length === 0 && (
        <div className="section container">
          <h2 className="mb-4 lg:mb-6 text-xl lg:text-2xl font-rc-plus font-bold text-center">
            {fields.title?.text === 'Gelateria'
              ? 'Op dit moment is het nog niet mogelijk om ijs te bestellen via onze webshop'
              : 'Geen producten gevonden'}
          </h2>
        </div>
      )}
    </section>
  )
}
